import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { CommonJumbotron } from "../../components/jumbotron";
import { SlidingTypography } from "../../components/sliding";
import ContainerBox from "../../components/containerBox";
import { pageContextType } from "../../pageContextType";

const useStyles = makeStyles(theme => createStyles({
  content: {
    paddingTop: "160px",
    paddingBottom: "80px",
  },
  flexRight: {
    display: "flex",
    flexDirection: "row-reverse",
    paddingTop: "80px",
  },
  sectionChunk: {
    marginBottom: "80px",
  },
  title: {
    color: "#222222",
    fontSize: "40px",
    fontWeight: 700,
    marginBottom: "20px",
    lineHeight: "1.3",
    maxWidth: "670px",
    wordBreak: "keep-all",
  },
  description: {
    color: "#858585",
    fontSize: "16px",
    marginBottom: "20px",
    maxWidth: "670px",

    "&:last-child": {
      marginBottom: 0,
    },
  },
  sectionImage: {
    height: "360px",
    objectFit: "cover",
    marginBottom: "80px",
  },
  link: {
    color: "#858585",
  },
}));

const DataPage = ({ pageContext }: pageContextType) => {
  const classes = useStyles();
  const intl = useIntl();
  const { language } = pageContext;
  const data = useStaticQuery(graphql`
    query {
      backgroundPlaceholder: file(
        relativePath: { eq: "tech/img-title-photo-tech-03@3x.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DataPlaceholder1: file(
        relativePath: { eq: "tech/img-tech-data-01@3x.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DataPlaceholder2: file(
        relativePath: { eq: "tech/img-tech-data-02@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout language={language}>
      <SEO title="Data" description={intl.formatMessage({ id: "data_lead" })} />
      <CommonJumbotron
        backgroundImageFluid={data.backgroundPlaceholder.childImageSharp.fluid}
        title={"Data"}
      />

      <main className={classes.content}>
        <ContainerBox>
          <div className={classes.sectionChunk}>
            <SlidingTypography variant="h2" className={classes.title}>
              {intl.formatMessage({ id: "data_title_1" })}
            </SlidingTypography>
            <SlidingTypography component="p" className={classes.description}>
              {intl.formatMessage({ id: "data_description_1" })}
            </SlidingTypography>
          </div>
        </ContainerBox>

        <ContainerBox leftOnly>
          <Img
            className={classes.sectionImage}
            fluid={data.DataPlaceholder1.childImageSharp.fluid}
            alt={intl.formatMessage({ id: "data_title_1" })}
          />
        </ContainerBox>

        <ContainerBox className={classes.flexRight}>
          <div>
            <div className={classes.sectionChunk}>
              <SlidingTypography variant="h2" className={classes.title}>
                {intl.formatMessage({ id: "data_title_2" })}
              </SlidingTypography>
              <SlidingTypography component="p" className={classes.description}>
                {intl.formatMessage({ id: "data_description_2" })}
              </SlidingTypography>
            </div>

            <div className={classes.sectionChunk}>
              <SlidingTypography variant="h2" className={classes.title}>
                {intl.formatMessage({ id: "data_title_3" })}
              </SlidingTypography>
              <SlidingTypography component="p" className={classes.description}>
                {intl.formatMessage({ id: "data_description_3" })}
              </SlidingTypography>
            </div>
          </div>
        </ContainerBox>

        <ContainerBox rightOnly>
          <Img
            className={classes.sectionImage}
            fluid={data.DataPlaceholder2.childImageSharp.fluid}
            alt={intl.formatMessage({ id: "data_title_2" })}
          />
        </ContainerBox>
      </main>
    </Layout>
  );
};

export default DataPage;
